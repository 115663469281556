//部门下拉树 已有新组件selectDept，逐步转移中
<template>
    <div :class="{'hidden':hidden,
    'isScorll': isScorll }"
         class="el-row">
        <selectTree ref="selectTree"
                    :treeData="orgList"
                    :defaultProps="defaultProps"
                    :searchForm="searchForm"
                    :placeholderStr="placeholderStr"
                    :showCheckbox="showCheckbox"
                    :shouldClean="shouldClean"
                    :checkedInfo="checkedInfo"
                    :needClean="needClean"
                    :expandOnClickNode="expandOnClickNode"
                    @handClear="handClear"
                    :disabled="isDisabled || disabled"
                    @treeSelectNodeClick="treeSelectNodeClick"></selectTree>
    </div>
</template>

<script>
import selectTree from "@/components/selectTree";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "deptSelectTree",
    components: { selectTree },
    props: {
        treeData: {
            type: Array,
            default() {
                return [];
            },
        },
        defaultProps: {
            type: Object,
            default() {
                return {
                    children: "children",
                    label: "label",
                };
            },
        },
        searchForm: {
            type: Object,
            default() {
                return {
                    label: "",
                    value: "",
                };
            },
        },
        placeholderStr: {
            type: String,
            default: "请选择部门",
        },
        hidden: {
            type: Boolean,
            default: false,
        },
        //是否显示多选
        showCheckbox: {
            type: Boolean,
            default: false,
        },
        // 回显选中的值
        checkedInfo: {
            type: Object,
            default() {
                return {
                    label: "",
                    value: "",
                };
            },
        },
        //组织冒泡关闭
        expandOnClickNode: {
            type: Boolean,
            default: false,
        },

        //是否需要全部清空
        needClean: {
            // type: Boolean,
            default: false,
        },

        // 是否需要清空按钮  只能用于单选  多选不适用
        shouldClean: {
            type: Boolean,
            default: false,
        },
        // 是否需要滚动条
        isScorll: {
            type: Boolean,
            default: false,
        },
        // 是否禁用
        isDisabled: {
            type: Boolean,
            default: false,
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        // 表格内编辑所需index
        rowIndex: {
            default: null,
        },
    },
    data() {
        return {
            orgList: [], //组织机构列表
        };
    },
    mounted() {
        this.getOrgList();
    },
    computed: {},
    watch: {},
    methods: {
        // 点击/多选树
        treeSelectNodeClick(data) {
            if (this.rowIndex !== null) {
                this.$emit("treeSelectNodeClick", data, this.rowIndex);
                this.$emit("change");
            } else {
                this.$emit("treeSelectNodeClick", data);
                this.$emit("change");
            }
        },

        //获取组织结构下拉
        getOrgList() {
            commonAPI.getOrgList().then((res) => {
                this.orgList = res.content;
            });
        },

        //清空选择项 事件回调
        handClear() {
            if (this.rowIndex !== null) {
                this.$emit("handClear", this.rowIndex);
            } else {
                this.$emit("handClear", true);
            }
        },
        // 清空选择
        cleanSelect() {
            this.$refs.selectTree.cleanSelect();
        },
    },
};
</script>

<style scoped>
.pagination-container {
    background: #fff;
    margin-top: 20px;
    /* padding: 32px 16px; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pagination-container.hidden {
    display: none;
}
</style>
