// 新建系统
<template>
    <el-dialog :close-on-click-modal="false"  :title="title"
               :visible="true"
               width="500px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="100px"
                 :inline="false"
                 size="normal">
            <el-form-item label="系统名称"
                          prop="name">
                <el-input v-model="form.name"
                          placeholder="请输入系统名称"
                          clearable />
            </el-form-item>
            <el-form-item label="联系人">
                <el-input v-model="form.linkName"
                          placeholder="请输入联系人"
                          clearable />
            </el-form-item>
            <el-form-item label="管理部门"
                          prop="manageDeptId">
                      <deptSelectTree placeholderStr="请选择部门"
                                :checkedInfo="{value:form.manageDeptId,label:''}"
                                @treeSelectNodeClick="treeSelectNodeClick"></deptSelectTree>
            </el-form-item>
            <el-form-item label="备注"
                          prop="remark">
                <el-input v-model="form.memo"
                          type="textarea"
                          :rows="2"
                          placeholder="请输入备注"
                          clearable />
            </el-form-item>
            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           v-loading="isloading"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import baseManageApi from "@/api/baseManageApi.js";
import deptSelectTree from "@/components/selectTree/deptSelectTree";
export default {
    name: "systemDialog",
    components: {
        deptSelectTree,
    },
    props: {
        title: {
            type: String,
            default: "新增系统",
        },
        formData: {
            type: Object,
            default: null,
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal && (this.form = JSON.parse(JSON.stringify(newVal)));
            },
        },
    },
    data() {
        return {
            form: {
                name: "",
                linkName: "",
                memo: "",
                manageDeptId: null, // 管理部门id
            },
            isloading: false,
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入系统名称",
                    },
                ],
                manageDeptId: [
                    {
                        required: true,
                        message: "请选择管理部门",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    mounted() {},
    methods: {
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.formData ? this.updateSystem() : this.createSystem();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },
        //新建系统
        createSystem() {
            this.isloading = true;
            baseManageApi
                .saveSoft(this.form)
                .then(() => {
                    this.$message({ message: "新建成功", type: "success" });
                    this.isloading = false;
                    this.$emit("close");
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        //编辑系统
        updateSystem() {
            this.isloading = true;
            baseManageApi
                .updateSoft(this.form)
                .then(() => {
                    this.$message({ message: "修改成功", type: "success" });
                    this.isloading = false;
                    this.$emit("close");
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        //组织部门点击回调
        treeSelectNodeClick(data) {
            this.form.manageDeptId = data.id;
            this.$refs.form.validateField('manageDeptId');
        },
    },
};
</script>
<style scoped>
</style>