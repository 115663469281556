// 软件信息管理
<template>
    <div class="softwareInfoManage baseBg">
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="系统名称">
                    <el-input v-model="searchForm.name"
                              placeholder="请输入系统名称"
                              clearable />
                </el-form-item>

                <el-button type="primary"
                           @click="search">搜索</el-button>

                <el-button type=""
                           @click="resetSearchForm">清除</el-button>
                <el-button type="success"
                           class="flr"
                           @click="addNew">新建系统</el-button>
            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData"
                          border
                          :height="tableHeight"
                          v-loading="isloading"
                          stripe>
                    <el-table-column v-for="col in tableColumns"
                                     :prop="col.prop"
                                     :key="col.label"
                                     :label="col.label"
                                     :min-width="col.width">
                    </el-table-column>
                    <el-table-column label="操作"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row)">查看</el-link>
                            <el-link type="success"
                                     @click="editTable(scope.row)">编辑</el-link>
                            <el-popconfirm confirmButtonText='确认'
                                           cancelButtonText='取消'
                                           icon="el-icon-info"
                                           iconColor="red"
                                           @confirm="deleteTable(scope.row)"
                                           title="确定删除吗？">
                                <el-link slot="reference"
                                         type='danger'>删除</el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>
        <system-dialog v-if="systemDialogVisible"
                       :formData="rowData"
                       title="编辑系统"
                       @close="closeSystemDialog"
                       @refresh="search" />
    </div>
</template>

<script>
import baseManageApi from "@/api/baseManageApi.js";
import Pagination from "@/components/Pagination"; // 分页
import systemDialog from "./components/pop_addNewSystem";
export default {
    name: "softwareInfoManage",
    props: [],

    components: {
        Pagination,
        systemDialog,
    },

    data() {
        return {
            systemDialogVisible: false,
            searchForm: {
                name: null,
                page: 1,
                size: 20,
            },
            pageTotal: 0,
            isloading: true,
            rowData: null,
            tableColumns: [
                {
                    prop: "name",
                    label: "系统名称",
                    width: "100",
                },
                {
                    prop: "deptName",
                    label: "管理部门",
                    width: "100",
                },
                {
                    prop: "systemVersionNames",
                    label: "版本信息",
                    width: "100",
                },
                {
                    prop: "customerNames",
                    label: "使用地点",
                    width: "100",
                },
                {
                    prop: "linkName",
                    label: "联系人",
                    width: "100",
                },
                {
                    prop: "memo",
                    label: "备注",
                    width: "100",
                },
            ],
            tableData: [],
        };
    },

    created() {},

    mounted() {
        this.search();
    },

    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            baseManageApi
                .getSoftList(searchForm)
                .then((res) => {
                    this.isloading = false;
                    this.tableData = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        details(e) {
            this.$router.push({
                path: "/baseInfoManage/systemDetail",
                query: { systemInfo: JSON.stringify(e) },
            });
        },

        // 搜索
        search() {
            this.searchForm.page = 1;
            this.getList();
        },

        //清空筛选条件
        resetSearchForm() {
            this.searchForm.name = "";
            this.getList();
        },

        // 新增
        addNew() {
            this.rowData = null;
            this.systemDialogVisible = true;
        },
        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },
        // 新增或編輯系統彈框 关闭
        closeSystemDialog() {
            this.systemDialogVisible = false;
        },
        // 表格的删除操作
        deleteTable(row) {
            this.$confirm("确认删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.isloading = true;
                baseManageApi
                    .delSoft({ id: row.id })
                    .then(() => {
                        this.$message({
                            message: "删除成功",
                            type: "success",
                        });
                        this.getList();
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            });
        },

        // 表格的编辑操作
        editTable(row) {
            this.rowData = row;
            this.systemDialogVisible = true;
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 245 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
